var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "InvoicesPaid" },
    [
      _c("paid-search", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.mobile.isMobile,
            expression: "!mobile.isMobile"
          }
        ],
        staticClass: "InvoicesSearch",
        attrs: {
          page: _vm.pagination.page,
          "user-is-client": _vm.userIsClient
        },
        on: {
          "collapsed-state-change": function($event) {
            _vm.expandAll = !_vm.expandAll
          },
          "reset-pagination": _vm.resetPagination
        }
      }),
      _c("base-invoice-table", {
        attrs: {
          "expand-all": _vm.expandAll,
          status: "paid",
          "user-is-client": _vm.userIsClient
        }
      }),
      _c("base-pagination", {
        staticClass: "InvoicesSearch mt-40",
        attrs: {
          "item-count": _vm.searchInfo.total,
          page: _vm.pagination.page
        },
        on: { paginate: _vm.paginate }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }