<template>
  <div @keyup.enter.capture="triggerClickOnSearch">
    <label class="InvoicesSearch__page-label">Paid Invoices</label>

    <!-- UI for clients (additional filters and term not hidden) -->
    <div
      v-if="userIsClient"
      class="column"
    >
      <div class="InvoicesSearch__search-wrapper">
        <base-toggle
          @toggle="setPaidFilter"
          :active-option="inputs.paid"
          aria-label="Additional filter options (toggle)"
          class="InvoicesSearch__additional-filters-toggle"
          :label="'ADDITIONAL FILTERS'"
          :toggles="options.paid"
        />

        <v-select
          v-model="inputs.term"
          @input="setTerm"
          aria-label="Time As Declined select input"
          class="InvoicesSearch__term-select mt-25"
          :clearable="false"
          :options="options.terms"
          :placeholder="'Choose your term length'"
        />

        <base-button
          @click="clickOnSearch"
          class="InvoicesSearch__buttons bg-white fc-blue"
          data-cy="invoice-search-submit-btn"
        >
          <i class="fa fa-search-left" />
          Search
        </base-button>

        <base-button
          @click="showFilters = !showFilters"
          aria-label="Toggles the display of additional search fliter options"
          :class="[
            'InvoicesSearch__buttons InvoicesSearch__buttons-filter bg-white fc-blue',
            { 'InvoicesSearch__buttons--outlined': showFilters }
          ]"
          data-cy="invoice-search-filters-btn"
          name="Filters"
        >
          <i class="fa fa-optionbars" />
          Filters
        </base-button>
      </div>

      <!-- Search options hidden (toggled by filters button above) -->
      <transition name="Transition__opacity-fade">
        <div
          v-show="showFilters"
          class="InvoicesSearch__filters-wrapper"
        >
          <base-debtor-search
            @selected="setDebtorID"
            class="InvoicesSearch__debtor-input"
            :debtor-i-d-from-query="apiValues.debtorID"
            :label="true"
            :label-for="'Debtor'"
          >
            <template v-slot:label>
              DEBTOR
            </template>
          </base-debtor-search>

          <base-input
            v-if="!userIsClient"
            v-model.trim="inputs.id"
            class="InvoicesSearch__query-input"
            :instructions="instructions.ID_INSTRUCTION"
            :label="true"
            :label-for="'InvoiceID'"
            :type="'text'"
            :valid="validity.id"
          >
            INVOICE #
          </base-input>

          <base-input
            v-model.trim="inputs.loadNumber"
            class="InvoicesSearch__query-input"
            :instructions="instructions.QUERY_INSTRUCTION"
            :label="true"
            :label-for="'Query'"
            :type="'text'"
            :valid="validity.loadNumber"
          >
            LOAD #
          </base-input>
        </div>
      </transition>
    </div>

    <!-- UI for employees (client and load # not hidden) -->
    <div
      v-else
      class="column"
    >
      <div class="InvoicesSearch__search-wrapper">
        <base-client-search
          @selected="setClientID"
          class="InvoicesSearch__client-input"
          :client-i-d-from-query="apiValues.clientID"
          :label="true"
          :label-for="'Client'"
        >
          <template v-slot:label>
            CLIENT
          </template>
        </base-client-search>

        <base-input
          v-model.trim="inputs.loadNumber"
          class="InvoicesSearch__query-input"
          :instructions="instructions.QUERY_INSTRUCTION"
          :label="true"
          :label-for="'Query'"
          :type="'text'"
          :valid="validity.loadNumber"
        >
          LOAD #
        </base-input>

        <base-button
          @click="clickOnSearch"
          class="InvoicesSearch__buttons bg-white fc-blue"
          data-cy="invoice-search-submit-btn"
        >
          <i class="fa fa-search-left" />
          Search
        </base-button>

        <base-button
          @click="showFilters = !showFilters"
          aria-label="Toggles the display of additional search fliter options"
          :class="[
            'InvoicesSearch__buttons InvoicesSearch__buttons-filter bg-white fc-blue',
            { 'InvoicesSearch__buttons--outlined': showFilters }
          ]"
          data-cy="invoice-search-filters-btn"
          name="Filters"
        >
          <i class="fa fa-optionbars" />
          Filters
        </base-button>
      </div>

      <!-- Search options hidden (toggled by filters button above) -->
      <transition name="Transition__opacity-fade">
        <div
          v-show="showFilters"
          class="InvoicesSearch__filters-wrapper"
        >
          <base-debtor-search
            @selected="setDebtorID"
            class="InvoicesSearch__debtor-input"
            :debtor-i-d-from-query="apiValues.debtorID"
            :label="true"
            :label-for="'Debtor'"
          >
            <template v-slot:label>
              DEBTOR
            </template>
          </base-debtor-search>

          <base-input
            v-model.trim="inputs.id"
            class="InvoicesSearch__query-input"
            :instructions="instructions.ID_INSTRUCTION"
            :label="true"
            :label-for="'InvoiceID'"
            :type="'text'"
            :valid="validity.id"
          >
            INVOICE #
          </base-input>

          <base-toggle
            @toggle="setPaidFilter"
            :active-option="inputs.paid"
            aria-label="Additional filter options (toggle)"
            class="InvoicesSearch__additional-filters-toggle"
            :label="'ADDITIONAL FILTERS'"
            :toggles="options.paid"
          />

          <v-select
            v-model="inputs.term"
            @input="setTerm"
            aria-label="Time As Declined select input"
            class="InvoicesSearch__term-select mt-25"
            data-cy="invoice-search-term-filter"
            :clearable="false"
            :options="options.terms"
            :placeholder="'Choose your term length'"
          />
        </div>
      </transition>
    </div>

    <!-- Total invoices and value + Collapse toggles -->
    <div class="row row--align-center mb-25 mt-25 width-100">
      <label
        v-if="invoices.total > 0"
        class="InvoicesSearch__search-results-label"
        id="Testing__TotalSearchResults"
      >
        {{ invoices.total }} Total
        ({{
          Intl
            .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
            .format(invoices.sum / 100)
        }})
      </label>
      <label
        v-else
        class="InvoicesSearch__search-results-label"
        id="Testing__TotalSearchResults"
      >
        There are no invoices
      </label>

      <base-toggle
        v-if="!userIsClient"
        @toggle="emitCollapseStateChange"
        :active-option="expandCollapse"
        aria-label="Collapse or expand items"
        class="InvoicesSearch__expand-toggle"
        :toggles="options.expandCollapse"
      />
      <transition
        mode="out-in"
        name="Transition__opacity-fade"
      >
        <!-- Loading Indicator -->
        <div
          v-if="!userIsClient && !reportFinished"
          :key="'loading-indicator'"
        >
          <div class="InvoicesSearch__loading-indicator">
            <i class="fa fa-processing fa-spin fa--none fc-light fs-60" />
          </div>
        </div>
        <base-button
          v-else-if="!userIsClient"
          @click="reportModalVisible = true"
          @keydown.enter="reportModalVisible = true"
          class="bg-trans"
        >
          <i class="InvoicesSearch__aging-icon fa fa-invoice fa-20" />
        </base-button>
      </transition>
    </div>

    <transition name="Transition__fade">
      <!-- AGING DOWNLOAD MODAL (GIVES USER FILE TYPE DOWNLOAD OPTION) -->
      <base-modal v-if="reportModalVisible">
        <template v-slot:label>
          What format do you want?
        </template>

        <template v-slot:actions>
          <base-button
            @click="downloadInvoiceReport('xlsx')"
            class="bg-green fc-white mr-8"
          >
            EXCEL
          </base-button>
          <base-button
            @click="downloadInvoiceReport('pdf')"
            class="bg-red fc-white"
          >
            PDF
          </base-button>
          <base-button
            @click="reportModalVisible = false"
            class="bg-light fc-white ml-8"
          >
            CANCEL
          </base-button>
        </template>
      </base-modal>
    </transition>
  </div>
</template>

<script>
// Packages
import moment from 'moment'
// Helpers
import {
  Invoice
} from '../utils/api'
import { debounce, stringifyObjectToQueryString } from '../utils/helpers'
// Components
import BaseButton from './base-button.vue'
import BaseClientSearch from './base-client-search.vue'
import BaseDebtorSearch from './base-debtor-search.vue'
import BaseInput from './base-input.vue'
import BaseModal from './base-modal.vue'
import BaseToggle from './base-toggle.vue'
// Mixins
import {
  ValidationMixin
} from '../utils/validation-mixin'

export default {
  name: 'PaidSearch',

  components: {
    BaseButton,
    BaseClientSearch,
    BaseDebtorSearch,
    BaseInput,
    BaseModal,
    BaseToggle,
  },

  mixins: [ValidationMixin],

  props: {
    page: {
      type: Number,
      required: true,
      validator: (value) => value >= 1,
    },
    userIsClient: {
      type: Boolean,
      required: true,
    },
  },

  async created () {
    this.$store.commit('CLEAR_INVOICES')

    // paid_date needs initialized since the default value is 'past 6 months'
    this.setTerm('past 6 months')

    // Query string? Compare variables with component data; if different, perform search
    const queryString = window.location.search
    const queryParams = new URLSearchParams(queryString)

    if (queryString) {
      // Client ID
      if (queryParams.has('clientID')) {
        if (queryParams.get('clientID') !== this.apiValues.clientID) {
          this.$set(this.apiValues, 'clientID', queryParams.get('clientID'))
        }
      }

      // Debtor ID
      if (queryParams.has('debtorID')) {
        if (queryParams.get('debtorID') !== this.apiValues.debtorID) {
          this.$set(this.apiValues, 'debtorID', queryParams.get('debtorID'))
        }
      }

      // Invoice ID
      if (queryParams.has('id')) {
        if (queryParams.get('id') !== this.inputs.id) {
          this.$set(this.inputs, 'id', queryParams.get('id'))
        }
      }

      // Load Number
      if (queryParams.has('loadNumber')) {
        if (queryParams.get('loadNumber') !== this.inputs.loadNumber) {
          this.$set(this.inputs, 'loadNumber', queryParams.get('loadNumber'))
        }
      }

      // Additional Filters
      if (queryParams.has('status_debtor_payment')) {
        if (queryParams.get('status_debtor_payment') === 'overpaid') {
          this.$set(this.apiValues, 'overpay', true)
          this.$set(this.inputs, 'paid', 'overpaid')
        }
        if (queryParams.get('status_debtor_payment') === 'shortpaid') {
          this.$set(this.apiValues, 'shortpay', true)
          this.$set(this.inputs, 'paid', 'shortpaid')
        }
      } else {
        // Clients should always have a status_debtor_payment, which defaults to shortpaid
        // eslint-disable-next-line no-lonely-if
        if (this.userIsClient) {
          this.$set(this.apiValues, 'shortpay', true)
          this.$set(this.inputs, 'paid', 'shortpaid')
        }
      }

      // Term
      if (queryParams.has('term')) {
        if (queryParams.get('term') !== this.inputs.term) {
          this.$set(this.inputs, 'term', queryParams.get('term'))
          this.setTerm(queryParams.get('term'))
        }
      } else {
        // Clients should always have a term, which defaults to past 6 months
        // eslint-disable-next-line no-lonely-if
        if (this.userIsClient) {
          this.$set(this.inputs, 'term', 'past 6 months')
          this.setTerm('past 6 months')
        }
      }
    } else {
      // Don't override the query params; these are client defaults
      // eslint-disable-next-line no-lonely-if
      if (this.userIsClient) {
        // Additional filters defaults to 'shortpaid'
        this.$set(this.apiValues, 'shortpay', true)
        this.$set(this.inputs, 'paid', 'shortpaid')
        // Term defaults to '6 months'
        this.$set(this.inputs, 'term', 'past 6 months')
        this.setTerm('past 6 months')
      }
    }

    // If userIsClient, set client_id; we NEED to override any client_id in the query params
    if (this.userIsClient) {
      const client = JSON.parse(localStorage.getItem('user')).client
      this.$set(this.apiValues, 'clientID', client.id)
    }

    this.search()
  },

  mounted () {
    // it needs to refresh results every 5 minutes for clients only
    if (this.userIsClient) {
      this.searchInterval = setInterval(() => {
        this.search()
      }, 5 * 60000)
    }
  },

  beforeDestroy() {
    // it should stop searching when you leave the page
    if (this.searchInterval) clearInterval(this.searchInterval)
  },

  data () {
    return {
      apiValues: {
        clientID: null,
        debtorID: null,
        gt_paid_date: null,
        lt_paid_date: null,
        overpay: null,
        shortpay: null,
      },
      expandCollapse: '<i class="fa fa-4-bars fa-15"></i>',
      inputs: {
        id: '',
        loadNumber: '',
        paid: 'all',
        term: 'past 6 months',
      },
      options: {
        expandCollapse: [
          '<i class="fa fa-4-bars fa-15"></i>',
          '<i class="fa fa-hamburger fa-15"></i>',
        ],
        paid: ['all', 'shortpaid', 'overpaid'],
        terms: ['none', 'past 6 months', 'current year', 'last year'],
      },
      reportFinished: true,
      reportModalVisible: false,
      resultsPerPage: 25,
      searchInterval: null,
      showFilters: false,
      validity: {
        id: true,
        loadNumber: true,
      },
    }
  },

  computed: {
    invoices () {
      return this.$store.getters.invoices
    }
  },

  watch: {
    page () {
      this.search()
    }
  },

  methods: {
    clickOnSearch() {
      // this.CLog('test')
      // this.debounce(
      //   () => {
      this.$emit('reset-pagination')
      this.search()
      //   },
      //   250
      // )
    },

    debounce,

    async downloadInvoiceReport (format = 'pdf') {
      // only run report if they have debtor or client selected
      if (!this.apiValues.clientID && !this.apiValues.debtorID) {
        this.reportModalVisible = false
        return this.alertWarning('You must select a client or debtor to generate a report')
      }

      // don't run report for more than 6 months of invoices
      const gt_paid_date = this.apiValues.gt_paid_date
      const lt_paid_date = this.apiValues.lt_paid_date
      const milliSecondsInSixMonths = Date.now() - moment().subtract(6, 'months').toDate().getTime() + 1000
      const searchRangeMilliSeconds = (new Date(lt_paid_date)).getTime() - (new Date(gt_paid_date)).getTime()

      if (
        !gt_paid_date || !lt_paid_date || searchRangeMilliSeconds > milliSecondsInSixMonths
      ) {
        return this.alertWarning('You must select a date range of no more than 6 months to generate a report')
      }

      this.reportModalVisible = false
      this.reportFinished = false
      let or___status_debtor_payment = null
      if (this.apiValues.overpay && !this.apiValues.shortpay) {
        or___status_debtor_payment = ['overpay']
      } else if (this.apiValues.shortpay && !this.apiValues.overpay) {
        // a nonpayment counts as a shortpay
        or___status_debtor_payment = ['shortpay', 'nonpayment']
      }

      try {
        // pass the search params to the b/e so it knows which invoices to grab
        const { invoice_report_url, file_name } = (await Invoice.getInvoiceReport({
          client_id: this.apiValues.clientID,
          debtor_id: this.apiValues.debtorID,
          include: 'all',
          display_id: this.inputs.id.length ? this.inputs.id : null,
          like___load_number: this.inputs.loadNumber.length ? this.inputs.loadNumber : null,
          limit: null,
          offset: (this.page - 1) * this.resultsPerPage,
          or___status_debtor_payment,
          gt___paid_date: this.apiValues.gt_paid_date,
          // Backend doesn't handle lt___ being passed when not absolutely needed well
          lt___paid_date: this.inputs.term === 'last year' ? this.apiValues.lt_paid_date : null,
          // 'pdf' or 'xlsx': returns the correct file type for download
          reportType: format,
          sort_by: 'paid_date',
          sort_direction: 'DESC',
          status: 'paid'
          // need to get ALL the invoice ids (no limit of 25)
        }))
          .data

        const a = document.createElement('a')
        a.style.display = 'none'
        document.body.appendChild(a)
        a.href = invoice_report_url
        a.setAttribute('download', file_name)
        a.setAttribute('target', '_blank')
        a.click()
        window.URL.revokeObjectURL(a.href)
        document.body.removeChild(a)
        this.reportFinished = true
      }
      catch (error) {
        this.reportFinished = true
        this.captureSentryEvent(
          'Paid Search "Download Report" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: 'There was an issue fetching the invoice report',
          title: 'Request error'
        })
      }
    },

    emitCollapseStateChange (e) {
      this.expandCollapse = e
      this.$emit('collapsed-state-change')
    },

    async search () {
      if (!this.validation()) return

      this.progressStart()

      let or___status_debtor_payment = null
      if (this.apiValues.overpay && !this.apiValues.shortpay) {
        or___status_debtor_payment = ['overpay']
      } else if (this.apiValues.shortpay && !this.apiValues.overpay) {
        // a nonpayment counts as a shortpay
        or___status_debtor_payment = ['shortpay', 'nonpayment']
      }
      // Build string for parsing on load (easier than parsing algolia str)
      const urlString = stringifyObjectToQueryString({
        clientID: this.apiValues.clientID,
        debtorID: this.apiValues.debtorID,
        id: this.inputs.id,
        loadNumber: this.inputs.loadNumber,
        page: this.page,
        status_debtor_payment: this.inputs.paid !== 'all' ? this.inputs.paid : null,
        term: this.inputs.term
      })
      // Tell invoices-paid.vue to reset pagination because we're performing a new search
      this.$emit('search')
      // searchParams are null if the user hasn't searched for anything
      // i.e. this covers the case a user just paginates the invoices from onload
      try {
        const results = (await Invoice.queryList({
          client_id: this.apiValues.clientID,
          debtor_id: this.apiValues.debtorID,
          display_id: this.inputs.id.length ? this.inputs.id : null,
          include: 'all',
          like___load_number: this.inputs.loadNumber.length ? this.inputs.loadNumber : null,
          offset: (this.page - 1) * this.resultsPerPage,
          or___status_debtor_payment,
          gt___paid_date: this.apiValues.gt_paid_date,
          // Backend doesn't handle lt___ being passed when not absolutely needed well
          lt___paid_date: this.inputs.term === 'last year' ? this.apiValues.lt_paid_date : null,
          sort_by: 'paid_date',
          sort_direction: 'DESC',
          status: 'paid'
        })).data
        // Convert URL to URL representing data of request
        window.history.replaceState({}, '', `?${urlString}`)
        this.$store.commit('STORE_INVOICES', results)
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Paid Search "Search" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
            urlString,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: 'There was an issue searching for potential matching invoices',
          title: 'Request error'
        })
      }
    },

    setClientID (filter) {
      if (!filter) {
        this.$set(this.apiValues, 'clientID', null)
        return
      }
      this.$set(this.apiValues, 'clientID', filter.id)
    },

    setDebtorID (filter) {
      if (!filter) {
        this.$set(this.apiValues, 'debtorID', null)
        return
      }
      this.$set(this.apiValues, 'debtorID', filter.id)
    },

    setPaidFilter (filter) {
      if (filter === 'all') {
        this.$set(this.apiValues, 'overpay', null)
        this.$set(this.apiValues, 'shortpay', null)
      }
      if (filter === 'overpaid') {
        this.$set(this.apiValues, 'overpay', true)
        this.$set(this.apiValues, 'shortpay', false)
      }
      if (filter === 'shortpaid') {
        this.$set(this.apiValues, 'overpay', false)
        this.$set(this.apiValues, 'shortpay', true)
      }

      this.$set(this.inputs, 'paid', filter)
    },

    setTerm (filter) {
      if (filter === 'past 6 months') {
        this.$set(this.apiValues, 'gt_paid_date', moment().subtract(6, 'months').toDate())
        this.$set(this.apiValues, 'lt_paid_date', moment().toDate())
      }
      if (filter === 'current year') {
        this.$set(this.apiValues, 'gt_paid_date', moment().startOf('year').toDate())
        this.$set(this.apiValues, 'lt_paid_date', moment().toDate())
      }
      if (filter === 'last year') {
        this.$set(this.apiValues, 'gt_paid_date', moment().startOf('year').subtract(1, 'years').toDate())
        this.$set(this.apiValues, 'lt_paid_date', moment().startOf('year').toDate())
      }
      if (filter === 'none') {
        this.$set(this.apiValues, 'gt_paid_date', null)
        this.$set(this.apiValues, 'lt_paid_date', null)
      }
    },

    triggerClickOnSearch () {
      // If none of the following elements
      const toMatch = document.activeElement.classList
      if (
        toMatch.contains('InvoicesSearch__buttons-filter')
        || toMatch.contains('vs__search')
      ) {
        return
      }
      this.clickOnSearch()
    },

    validation () {
      let valid = true

      if (this.inputs.id && !this.isValidID(this.inputs.id)) {
        this.$set(this.validity, 'id', false)
        valid = false
      } else {
        this.$set(this.validity, 'id', true)
      }

      if (this.inputs.loadNumber && !this.isValidLoadNumber(this.inputs.loadNumber, true)) {
        this.$set(this.validity, 'loadNumber', false)
        valid = false
      } else {
        this.$set(this.validity, 'loadNumber', true)
      }

      return valid
    },
  },
}
</script>

<style lang="sass">
@import '../styles/invoices-search.sass'
</style>
